import { Card, CbhIcon, Slider } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { Box, CardActionArea, Skeleton, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import { useIsJobInterviewsEnabled } from "../../Placements/useIsJobInterviewsEnabled";
import {
  type OnboardingRequirement,
  OnboardingRequirementStatuses,
  useOnboardingRequirements,
} from "../hooks/useOnboardingRequirements";
import { PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH } from "../PlacementCandidateModalRoutes";

function getBannerProps(
  requirements: OnboardingRequirement[],
  isJobInterviewsEnabled: boolean
): {
  title: string;
  description?: string;
} {
  if (
    requirements.every(
      (requirement) => requirement.status === OnboardingRequirementStatuses.MISSING
    )
  ) {
    return {
      title: `Complete ${requirements.length} requirements to start ${
        isJobInterviewsEnabled ? "booking interviews" : "applying jobs"
      }`,
    };
  }

  for (const requirement of requirements) {
    if (requirement.status === OnboardingRequirementStatuses.REJECTED) {
      return { title: `There was a problem with your ${requirement.name}. Please re-submit.` };
    }

    if (requirement.status === OnboardingRequirementStatuses.MISSING) {
      return { title: requirement.stepAction };
    }
  }

  return {
    title: `Almost ready ${isJobInterviewsEnabled ? "for interviews" : "to apply for jobs"}!`,
    description: "We're reviewing your documents",
  };
}

function getRequirementIcon(requirement: OnboardingRequirement) {
  if (requirement.status === OnboardingRequirementStatuses.MISSING) {
    return (
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: (theme) => theme.palette.background.tertiary,
          border: (theme) => `1px solid ${theme.palette.icon?.primary}`,
        }}
      />
    );
  }

  if (requirement.status === OnboardingRequirementStatuses.REJECTED) {
    return (
      <CbhIcon
        size="xSmall"
        type="triangle-exclamation"
        color={(theme) => theme.palette.error.main}
      />
    );
  }

  return <CbhIcon size="xSmall" type="checkmark-filled" />;
}

interface OnboardingRequirementsBannerProps {
  workerId: string;
}

export function OnboardingRequirementsBanner(props: OnboardingRequirementsBannerProps) {
  const { workerId } = props;
  const history = useHistory();
  const { requirements, onboardingProgress, isRejected, isLoading, numberOfCompletedSteps } =
    useOnboardingRequirements(workerId);
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();

  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_ONBOARDING_BANNER_VIEWED,
    {
      requirements,
      onboardingProgress,
      numberOfCompletedSteps,
    },
    {
      enabled: !isLoading,
    }
  );

  if (isLoading) {
    return (
      <Box sx={{ paddingX: 5 }}>
        <Skeleton animation="wave" variant="rounded" height={164} />
      </Box>
    );
  }

  if (onboardingProgress === 100) {
    return null;
  }

  const bannerProps = getBannerProps(requirements, isJobInterviewsEnabled);

  return (
    <Box sx={{ paddingX: 5 }}>
      <Card
        sx={{
          padding: 7,
          background: (theme) =>
            isRejected
              ? theme.palette.onboarding?.error.gradient
              : theme.palette.onboarding?.inProgress.gradient,
        }}
      >
        <CardActionArea
          onClick={() => {
            logEvent(APP_V2_APP_EVENTS.PLACEMENT_ONBOARDING_BANNER_CTA_CLICKED, {
              requirements,
              onboardingProgress,
              numberOfCompletedSteps,
            });
            history.push(PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH);
          }}
        >
          <Stack spacing={9}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: 7,
              }}
            >
              <Stack spacing={2}>
                <Title variant="h5" component="h2" sx={{ fontWeight: 500 }}>
                  {bannerProps.title}
                </Title>
                {bannerProps.description && <Text variant="body2">{bannerProps.description}</Text>}
              </Stack>
              <IconButton size="xSmall" variant="outlined" iconType="chevron-right" />
            </Box>

            <Stack spacing={3}>
              <Box display="flex" justifyContent="space-between" gap={2}>
                {requirements.map((requirement) => (
                  <Box key={requirement.name} display="flex" alignItems="center" gap={2}>
                    {getRequirementIcon(requirement)}
                    <Text variant="subtitle2" sx={{ fontWeight: 500 }}>
                      {requirement.stepLabel}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Slider
                thumb={false}
                value={Math.round((onboardingProgress / 100) * 100)}
                color={isRejected ? "error" : "primary"}
                sx={{
                  width: "100%",
                  paddingX: 0,
                  cursor: "default",
                  overflow: "hidden",
                }}
              />
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
    </Box>
  );
}
